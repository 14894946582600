<template>
  <v-card>
    <v-card-title class="secondary white--text pa-2">
      Categories
    </v-card-title>
    <v-list
      dense
      nav
    >
      <v-list-item-group
        v-model="selectedIndex"
        color="secondary"
      >
        <template v-if="blogCategories.length">
          <v-list-item
            v-for="category in blogCategories"
            :key="category.id"
            class="category-list-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ category.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-else>
          <v-list-item v-if="showLoader">
            <v-list-item-content>
              <div class="d-flex justify-center pa-5">
                <v-progress-circular
                  indeterminate
                  size="40"
                  width="3"
                />
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-content>
              <div>No Categories</div>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'BlogCategories',
  props: {
    selectedCategoryId: {
      type: [Number, null]
    },
  },

  data() {
    return {
      selectedIndex: null,
      showLoader: true
    };
  },

  computed: {
    blogCategories() {
      return this.$store.state.blog.data.categories || [];
    },
  },

  watch: {
    selectedCategoryId: {
      handler(nv) {
        this.selectSelectedCategory(nv);
      },
      immediate: true
    },

    blogCategories() {
      if (this.selectedIndex !== null) return;

      this.selectSelectedCategory(this.selectedCategoryId);
    },

    selectedIndex: function (nv) {

      const selectedCategory = this.blogCategories[nv];

      this.$emit('valueChanged', selectedCategory);
    }
  },

  mounted() {
    setTimeout(() => {
      this.showLoader = false;
    }, 2500);
  },

  methods: {
    selectSelectedCategory(selectedCategoryId) {
      if (!this.blogCategories) return;

      const selectedCategoryIndex = this.blogCategories.findIndex(cat => cat.id === selectedCategoryId);

      if (selectedCategoryIndex < 0) return;

      this.selectedIndex = selectedCategoryIndex;
    },

  }
};
</script>

<style scoped>
.category-list-item {
  font-size: 15px;
  border-bottom: 1px solid #d4e2f3;
  margin-bottom: 0
}

.category-list-item:last-of-type {
  border-bottom: 0;
}

</style>
