<template>
  <v-overlay
    :absolute="absolutePosition"
    :value="loading"
    :z-index="zIndex"
  >
    <v-progress-circular
      :size="size"
      :style="`max-height: ${maxHeight}`"
      indeterminate
    />
  </v-overlay>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'PagePreLoader',
  props: {

    loading: {
      type: Boolean,
    },

    disableScroll: {
      type: Boolean,
      default: false
    },

    absolutePosition: {
      type: Boolean,
      default: true
    },

    zIndex: {
      type: [Number, String],
      default: 1
    },

    size: {
      type: [Number, String],
      default: 64
    },

    maxHeight: {
      type: String,
      default: '100vh'
    }
  },
  setup(props) {

    const scrollDisable = () => {
      if (props.loading && props.disableScroll) {
          document.body.style.overflowY = 'hidden';
          // document.body.style.height = '100vh';
      } else {
        document.body.style.overflowY = 'auto';
      }
    };

    onMounted(() => {
      scrollDisable();
    });

    return {};
  }
};
</script>

<style scoped>
.v-overlay--absolute {
  position: absolute;
  height: 100vh;
}
</style>
