<template>
  <v-container class="my-3">
    <PagePreLoader :loading="isPostsLoading" />

    <v-row v-if="!isLoading && post">
      <!--left content-->
      <v-col
        cols="12"
        lg="3"
      >
        <BlogCategories
          :selected-category-id="selectedCategoryId"
          @valueChanged="handleCategorySelect"
        />

        <v-card
          v-if="latestPost"
          class="mt-5"
        >
          <v-card-title class="secondary pa-2 white--text">
            Recent
          </v-card-title>

          <v-card-text class="pa-0">
            <blog-simple
              :post="latestPost"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!--right content-->
      <v-col
        class=""
        cols="12"
        lg="9"
      >
        <v-card>
          <v-card-text>
            <div class="blog-page__item">
              <div
                v-if="post.image"
                class="blog-page__img pa-0"
              >
                <img
                  :alt="post.title"
                  :src="post.image.url"
                >
              </div>

              <!--when post has image-->
              <div
                v-if="!post.image"
                class="blog-page__title primary--text"
              >
                {{ post.title }}
              </div>
              <v-divider v-if="!post.image" />


              <div class="blog-page__content pa-0">
                <!--show on bigger device-->
                <div class="hidden-sm-and-down">
                  <div class="blog-page-code">
                    <span class="blog-page-code__author">Author : {{ post.author.full_name }}</span>
                    <span class="blog-page-code__date">Published : {{ post.created_at | date }} </span>
                  </div>
                  <div class="blog-page-meta mt-1">
                    <div class="blog-page-meta__category">
                      Category :
                      <span
                        class="primary--text cursor-pointer"
                        @click="navigateToBlogsByCategory"
                      >
                        {{ post.category.name }}
                      </span>
                    </div>
                    <div class="blog-page-meta__tags">
                      Tagged :
                      <span
                        v-for="tag in post.tags"
                        :key="tag.id"
                        class="mr-2 primary--text"
                      >
                        {{ tag.name }}
                      </span>
                    </div>
                  </div>
                </div>

                <!--show on mobile device-->
                <div class="hidden-md-and-up mt-10">
                  <div>Author : <strong>{{ post.author.full_name }}</strong></div>
                  <div>Published : <strong>{{ post.created_at | date }}</strong></div>
                  <div>
                    Tagged :
                    <span
                      v-for="tag in post.tags"
                      :key="tag.id"
                      class="mr-1 primary--text"
                    >
                      {{ tag.name }}
                    </span>
                  </div>
                </div>
                <v-divider />

                <!--when post has image-->
                <div
                  v-if="post.image"
                  class="blog-page__title primary--text"
                >
                  {{ post.title }}
                </div>
                <v-divider v-if="post.image" />

                <div
                  class="blog-page__text text-left"
                  v-html="post.body"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogSimple from '@/components/Blog/BlogSimple';
import '@/assets/stylesheets/Page/BlogSingle.scss';
import BlogCategories from '@/components/Blog/BlogCategories';
import PagePreLoader from '@/components/Utils/PagePreLoader';
import { mapActions } from 'vuex';
import routeMap from '@/utils/routeMap';

export default {
  name: 'BlogDetails',
  components: { PagePreLoader, BlogCategories, BlogSimple },

  data: () => ({
    selectedCategoryId: null,
    post: {},
  }),

  computed: {
    postSlugOnUrl() {
      return this.$route.params.slug || '';
    },

    isLoading() {
      return this.$store.getters.isLoading;
    },

    isPostsLoading() {
      return this.$store.getters['blog/loading'];
    },

    allPosts() {
      return this.$store.state.blog.data.posts || [];
    },

    latestPost() {
      return this.$store.state.blog.data.latestPost || false;
    }
  },

  watch: {
    allPosts: {
      immediate: true,
      handler() {
        this.getPost();
      }
    },

    selectedCategoryId(nv) {
      if (!nv) return;

      const category = this.$store.state.blog.data.categories.find(cat => cat.id === nv);

      if (!category) return;

      this.navigateToBlogsByCategory(category.slug);
    },

    postSlugOnUrl(nv) {
      if (!nv) return;

      this.getPost();
    }
  },

  mounted() {
    this.getPost();

    // initially go to top
    this.$vuetify.goTo(0);
  },

  methods: {
    ...mapActions({
      getBlogPosts: 'blog/getBlogPosts'
    }),

    getPost() {
      // load posts if not available
      if (!this.allPosts.length && !this.isPostsLoading) {
        this.getBlogPosts({
          categoryId: null,
          pageIndex: null
        });
      }

      let slug = this.postSlugOnUrl;
      let post = this.allPosts.find(pst => pst.slug === slug);

      if (!post) {
        post = this.latestPost;
      }

      this.post = post;
    },

    handleCategorySelect(category) {
      this.selectedCategoryId = category?.id || null;
    },

    navigateToBlogsByCategory(categorySlug) {

      const catSlug =  typeof categorySlug === 'string' ? categorySlug : null;

      const categoryName = catSlug ? catSlug : this.post.category.slug;

      const link = {
        name: routeMap.blog.name,
        query: {
          category: categoryName
        }
      };

      this.$router.push(link);
    }
  },
};
</script>

<style lang="scss">
.blog-page__img img {
  max-width: 100%;
  height: auto;
}
.blog-page__text {
  font-size: 16px;
}
</style>
